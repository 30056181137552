




































import { Vue, Component } from 'vue-property-decorator'
import HeaderBox from '@/components/HeaderBox.vue'
import ImagePreview from '@/components/ImagePreview.vue'

interface Inventory {
  title: string
  date: string
  submiter: string
  remark: string
  image_urls: string[]
  roles: string[]
}

@Component({
  components: {
    HeaderBox,
    ImagePreview
  }
})
export default class InventoryRecord extends Vue {
  inventoryRecord: any = []
  currentImages = []
  currentIndex = 0
  showPreviewWindow = false

  created () {
    this.getInventory();
  }

  async getInventory () {
    const { code, data } = await this.$rest.dataCenter.getInventory();
    if (code !== 0) return;
    this.inventoryRecord = data;
  }

  handleLookBigPic (urls, index) {
    this.currentIndex = +index;
    this.currentImages = this.formatBigImages(urls);
    this.showPreviewWindow = true;
  }
}
